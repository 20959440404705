{
    "languages" : {
        "en" : {
            "hello" : "Hello",
            "welcome" : "Welcome to Progranimate!",
            "sign-in" : "Log In",
            "sign-up" : "Sign Up",
            "press-to-try" : "Press this button to try out Progranimate!",
            "press-button" : "Press Here for Progranimate",
            "why" : "Some reasons to try out Progranimate:",
            "element1" : "Flowchart representation of how code execution works",
            "element2" : "Visual helper to understand flow control and function calls",
            "element3" : "Supports loops, conditional statements, and functions",
            "element4" : "Has visual terminal to display system inputs and outputs",
            "element5" : "Runs animation to show process of code execution",
            "element6" : "Displays variables created and changed during run time",
            "element7" : "Perfect for beginners starting Python and Java",
            "update" : "Updates",
            "update1" : "Developed welcome page for users",
            "contact" : "Contact Information"
        },
        "es" : {
            "hello" : "Hola",
            "welcome" : "¡Bienvenidos a Progranimate!",
            "sign-in" : "Acceso",
            "sign-up" : "Inscribirse",
            "press-to-try" : "¡Presiona este botón para probar Progranimate!",
            "press-button" : "Presione aquí para Progranimate",
            "why" : "Algunas razones para probar Progranimate:",
            "element1" : "Representación de diagrama de flujo de cómo funciona la ejecución de código",
            "element2" : "Ayuda visual para comprender el control de flujo y las llamadas a funciones",
            "element3" : "Admite bucles, declaraciones condicionales y funciones",
            "element4" : "Tiene terminal visual para mostrar las entradas y salidas del sistema",
            "element5" : "Ejecuta una animación para mostrar el proceso de ejecución del código",
            "element6" : "Muestra variables creadas y modificadas durante el tiempo de ejecución",
            "element7" : "Perfecta para principiantes a partir de Python y Java",
            "update" : "Actualizaciones",
            "update1" : "Página de bienvenida desarrollada para los usuarios",
            "contact" : "Información del contacto"
        },
        "fr" : {
            "hello" : "Bonjour",
            "welcome" : "Bienvenue sur Progranimate !",
            "sign-in" : "Se connecter",
            "sign-up" : "S'inscrire",
            "press-to-try" : "Appuyez sur ce bouton pour essayer Progranimate !",
            "press-button" : "Appuyez ici pour Progranimate",
            "why" : "Quelques raisons d'essayer Progranimate :",
            "element1" : "Représentation sous forme d'organigramme du fonctionnement de l'exécution du code",
            "element2" : "Aide visuelle pour comprendre le contrôle de flux et les appels de fonction",
            "element3" : "Prend en charge les boucles, les instructions conditionnelles et les fonctions",
            "element4" : "Possède un terminal visuel pour afficher les entrées et sorties du système",
            "element5" : "Exécute une animation pour montrer le processus d'exécution du code",
            "element6" : "Affiche les variables créées et modifiées pendant l'exécution",
            "element7" : "Parfait pour les débutants débutant Python et Java",
            "update" : "Mises à jour",
            "update1" : "Page d'accueil développée pour les utilisateurs",
            "contact" : "Coordonnées"
        },
        "de" : {
            "hello" : "Hallo",
            "welcome" : "Willkommen bei Progranimate!",
            "sign-in" : "Anmeldung",
            "sign-up" : "Melden Sie sich an",
            "press-to-try" : "Klicken Sie auf diese Schaltfläche, um Progranimate auszuprobieren!",
            "press-button" : "Klicken Sie hier für Progranimate",
            "why" : "Einige Gründe, Progranimate auszuprobieren:",
            "element1" : "Flussdiagrammdarstellung der Funktionsweise der Codeausführung",
            "element2" : "Visueller Helfer zum Verständnis von Flusskontrolle und Funktionsaufrufen",
            "element3" : "Unterstützt Schleifen, bedingte Anweisungen und Funktionen",
            "element4" : "Verfügt über ein visuelles Terminal zur Anzeige der Systemeingänge und -ausgänge",
            "element5" : "Führt eine Animation aus, um den Prozess der Codeausführung zu zeigen",
            "element6" : "Zeigt zur Laufzeit erstellte und geänderte Variablen an",
            "element7" : "Perfekt für Anfänger, die mit Python und Java beginnen",
            "update" : "Aktualisierung",
            "update1" : "Willkommensseite für Benutzer entwickelt",
            "contact" : "Kontaktinformationen"
        },
        "zh" : {
            "hello" : "你好",
            "welcome" : "欢迎来到编程！",
            "sign-in" : "登录",
            "sign-up" : "报名",
            "press-to-try" : "按此按钮尝试计划！",
            "press-button" : "按此处获取计划",
            "why" : "尝试计划的一些理由：",
            "element1" : "代码执行工作原理的流程图表示",
            "element2" : "用于理解流程控制和函数调用的可视化助手",
            "element3" : "支持循环、条件语句和函数",
            "element4" : "具有可视终端显示系统输入和输出",
            "element5" : "运行动画以显示代码执行过程",
            "element6" : "显示运行时创建和更改的变量",
            "element7" : "非常适合 Python 和 Java 初学者",
            "update" : "更新",
            "update1" : "为用户开发欢迎页面",
            "contact" : "联系信息"
        },
        "hi" : {
            "hello" : "नमस्ते",
            "welcome" : "प्रोग्राम में आपका स्वागत है!",
            "sign-in" : "लॉग इन करें",
            "sign-up" : "साइन अप करें",
            "press-to-try" : "प्रोग्रेनिमेट को आज़माने के लिए इस बटन को दबाएँ!",
            "press-button" : "प्रोग्राम के लिए यहां दबाएं",
            "why" : "प्रोग्रेनिमेट को आज़माने के कुछ कारण:",
            "element1" : "कोड निष्पादन कैसे काम करता है इसका फ़्लोचार्ट प्रतिनिधित्व",
            "element2" : "प्रवाह नियंत्रण और फ़ंक्शन कॉल को समझने के लिए दृश्य सहायक",
            "element3" : "लूप, सशर्त कथन और फ़ंक्शंस का समर्थन करता है",
            "element4" : "सिस्टम इनपुट और आउटपुट प्रदर्शित करने के लिए विज़ुअल टर्मिनल है",
            "element5" : "कोड निष्पादन की प्रक्रिया दिखाने के लिए एनीमेशन चलाता है",
            "element6" : "रन टाइम के दौरान बनाए गए और बदले गए वेरिएबल प्रदर्शित करता है",
            "element7" : "पायथन और जावा शुरू करने वाले शुरुआती लोगों के लिए बिल्कुल सही",
            "update" : "अपडेट",
            "update1" : "उपयोगकर्ताओं के लिए स्वागत पृष्ठ विकसित किया गया",
            "contact" : "संपर्क जानकारी"
        },
        "ru" : {
            "hello" : "Привет",
            "welcome" : "Добро пожаловать в Програнимат!",
            "sign-in" : "Авторизоваться",
            "sign-up" : "Зарегистрироваться",
            "press-to-try" : "Нажмите эту кнопку, чтобы попробовать Progranimate!",
            "press-button" : "Нажмите здесь для Progranimate",
            "why" : "Несколько причин попробовать Progranimate:",
            "element1" : "Блок-схема того, как работает выполнение кода",
            "element2" : "Визуальный помощник для понимания управления потоком и вызовов функций.",
            "element3" : "Поддерживает циклы, условные операторы и функции",
            "element4" : "Имеет визуальный терминал для отображения входов и выходов системы",
            "element5" : "Запускает анимацию, показывающую процесс выполнения кода",
            "element6" : "Отображает переменные, созданные и измененные во время выполнения",
            "element7" : "Идеально подходит для начинающих, начинающих работать с Python и Java",
            "update" : "Обновления",
            "update1" : "Разработали приветственную страницу для пользователей",
            "contact" : "Контактная информация"
        },
        "ja" : {
            "hello" : "こんにちは",
            "welcome" : "プログラニメイトへようこそ！",
            "sign-in" : "ログイン",
            "sign-up" : "サインアップ",
            "press-to-try" : "このボタンを押して Progranimate を試してみましょう!",
            "press-button" : "プログラニメイトについてはここを押してください",
            "why" : "Progranimate を試す理由は次のとおりです。",
            "element1" : "=コード実行の仕組みをフローチャートで表現",
            "element2" : "フロー制御と関数呼び出しを理解するための視覚的なヘルパー",
            "element3" : "ループ、条件文、関数をサポート",
            "element4" : "システムの入力と出力を表示するビジュアルターミナルを備えています",
            "element5" : "コード実行のプロセスを示すアニメーションを実行します。",
            "element6" : "実行時に作成および変更された変数を表示します",
            "element7" : "Python と Java を始める初心者に最適",
            "update" : "アップデート",
            "update1" : "ユーザー向けのウェルカムページを開発しました",
            "contact" : "連絡先"
        },
        "ar" : {
            "hello" : "!مرحبًا بك في برنامجنا",
            "welcome" : "!مرحبًا بك في برنامجنا",
            "sign-in" : "تسجيل الدخول",
            "sign-up" : "اشتراك",
            "press-to-try" : "اضغط على هذا الزر لتجربة Progranimate!",
            "press-button" : "اضغط هنا للبرمجة",
            "why" : "بعض الأسباب لتجربة Progranimate:",
            "element1" : "تمثيل مخطط انسيابي لكيفية عمل تنفيذ التعليمات البرمجية",
            "element2" : "مساعد بصري لفهم التحكم في التدفق واستدعاءات الوظائف",
            "element3" : "يدعم الحلقات والعبارات الشرطية والوظائف",
            "element4" : "يحتوي على محطة مرئية لعرض مدخلات ومخرجات النظام",
            "element5" : "يقوم بتشغيل الرسوم المتحركة لإظهار عملية تنفيذ التعليمات البرمجية",
            "element6" : "يعرض المتغيرات التي تم إنشاؤها وتغييرها أثناء وقت التشغيل",
            "element7" : "مثالية للمبتدئين الذين يبدأون لغة بايثون وجافا",
            "update" : "التحديثات",
            "update1" : "صفحة ترحيب مطورة للمستخدمين",
            "contact" : "معلومات الاتصال"
        }
    }
}
