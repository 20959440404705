
import Languages from "./languages.json";

/**
 * Retrieves a string that correlates to the specific language that the user's browser is in and
 * the key inputted as a parameter. Provides the English translation if unsupported language is
 * used.
 * 
 * @param key A string literal that represents a field within the language object.
 * @returns The value that is mapped to the key.
 */
export function getString(key : string) : string {
    var lang : string|undefined = Languages.languages[navigator.language.slice(0,2)][key];
    //If language is not supported, returns the English translation
    if(typeof lang == "undefined") lang = Languages.languages["en"][key];
    return lang;
}
