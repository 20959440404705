
import { getString } from "./translation/translate_lang.ts"

/**
 * Loads translations to the welcome page. Translates buttons first, then headings, and then list
 * elements within the list.
 * 
 * Needs to get converted to Javascript for welcome website.
 */
function loadTranslation() {
    //buttons
    document.getElementById("sign-up").innerText = getString("sign-up");
    document.getElementById("log-in").innerText = getString("sign-in");
    document.getElementById("try-out-button").innerText = getString("press-button");

    //headings and paragraphs
    document.getElementById("welcome").innerText = getString("welcome");
    document.getElementById("try-out").innerText = getString("press-to-try");
    document.getElementById("why").innerText = getString("why");
    document.getElementById("updates").innerText = getString("update");
    document.getElementById("contact").innerText = getString("contact");

    //list elements - may need to be refactored
    var advertisementList = document.getElementById("element").getElementsByTagName("li"); 
    for(var i = 0; i < advertisementList.length; i++) {
        let cnt = i + 1;
        advertisementList[i].innerText = getString("element" + cnt);
    }
    var updateList = document.getElementById("update").getElementsByTagName("li");
    for(i = 0; i < updateList.length; i++) {
        let cnt = i + 1;
        updateList[i].innerText = getString("update" + cnt);
    }
}

loadTranslation();